import { buildUrlPrefix } from "@shopware/helpers";
import { inject } from "vue";

export function useUrlResolver() {
  const getUrlPrefix = () => inject("urlPrefix", "");

  const resolveUrl = (url: string) => {
    // @see: https://codeql.github.com/codeql-query-help/javascript/js-polynomial-redos/
    if (url.length > 2083) {
      throw new Error("URL Input too long");
    }

    const navigationPattern = /[a-zA-Z0-9]+\/navigation\/[a-zA-Z0-9]+/;
    const detailPattern = /[a-zA-Z0-9]+\/detail\/[a-zA-Z0-9]+/;
    const urlPrefix = getUrlPrefix();

    if (navigationPattern.test(url) || detailPattern.test(url)) {
      const newUrl = url.split("/").slice(1);

      if (newUrl.length > 0) {
        return buildUrlPrefix(newUrl.join("/"), urlPrefix).path;
      }
    }

    return url;
  };

  return {
    resolveUrl,
    getUrlPrefix,
  };
}
